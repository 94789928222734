import axios from "axios";

const instance = axios.create({
  baseURL: getBaseUrl(),
});

function getBaseUrl() {
  const hostname = window.location.hostname;

  console.log("hostname", hostname);

  if (hostname === "app.bevcglobal.com" || hostname === "web.bevcglobal.com") {
    return "https://backend.bevcglobal.com/api/";
  } else {
    return "http://127.0.0.1:8000/api/";
  }
}

// List of routes where headers should be disabled
const routesWithoutHeaders = [
  "/accounts/signup/verify/?code=",
  "/accounts/login",
  "/accounts/signup/",
  "/accounts/password/reset/",
  "/accounts/password/reset/confirm/",
  "https://backend.thinkiqbs.com/api/socials/whatsapp-messages/",
];

instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const disableHeaders = routesWithoutHeaders.some((route) =>
      config.url.includes(route),
    );

    if (!disableHeaders) {
      config.headers.Authorization = `Token ${localStorage.getItem("appToken")}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default instance;
