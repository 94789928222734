<template>
  <div class="loader">
    <img src="@/assets/Procus1.png" alt="Organization Logo" class="logo" />
  </div>
</template>

<script setup></script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
}

.logo {
  width: 100px; /* Adjust the size as needed */
  animation: vibrate 0.5s infinite;
}

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
