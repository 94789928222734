<template>
  <MainTemplate>
    <template #content>
      <div class="notifications-page">
        <div class="notifications-controls">
          <input
            v-model="searchQuery"
            placeholder="Search notifications..."
            class="input-search"
            @input="fetchNotifications(1)"
          />

          <div class="filters">
            <button
              :class="{ active: filter === 'all' }"
              @click="filter = 'all'"
            >
              All
            </button>
            <button
              :class="{ active: filter === 'unread' }"
              @click="filter = 'unread'"
            >
              Unread
            </button>
            <button
              :class="{ active: filter === 'read' }"
              @click="filter = 'read'"
            >
              Read
            </button>
          </div>

          <div class="sort">
            <select
              v-model="sortBy"
              class="select-sort"
              @change="fetchNotifications(1)"
            >
              <option value="date">Date</option>
              <option value="importance">Importance</option>
            </select>
            <button class="button-sort" @click="toggleSortOrder">
              <i :class="sortOrderIcon" />
            </button>
          </div>
        </div>

        <div class="notification-list">
          <h2>Notifications</h2>

          <div class="notification-table">
            <div class="notification-table-header">
              <div class="notification-table-row">
                <div class="notification-table-cell">Event Type</div>
                <div class="notification-table-cell">Message</div>
                <div class="notification-table-cell">Urgency</div>
                <div class="notification-table-cell">Created At</div>
                <div class="notification-table-cell">Sent At</div>
                <div class="notification-table-cell">Read</div>
                <div class="notification-table-cell">Delivered</div>
                <div class="notification-table-cell">Actions</div>
              </div>
            </div>
            <div class="notification-table-body">
              <div v-if="notifications.length">
                <div
                  v-for="notification in paginatedNotifications"
                  :key="notification.id"
                  class="notification-table-row"
                  :class="{ 'notification-read': notification.read }"
                >
                  <div class="notification-table-cell">
                    {{ notification.event_type }}
                  </div>
                  <div class="notification-table-cell">
                    <div v-html="notification.message" />
                  </div>
                  <div class="notification-table-cell">
                    {{ notification.urgency }}
                  </div>

                  <div class="notification-table-cell">
                    {{ notification.created_at ? new Date(notification.created_at).toLocaleString() : '' }}
                  </div>
                  <div class="notification-table-cell">
                    {{ notification.sent_at ? $moment(notification.sent_at).format('YYYY-MM-DD HH:mm') : '' }}
                  </div>
                  <div class="notification-table-cell">
                    <i class="bi" :class="notification.read ? 'bi-check-circle-fill' : 'bi-circle-fill'"></i>
                  </div>
                  <div class="notification-table-cell">
                    <i class="bi" :class="notification.is_delivered ? 'bi-check-circle-fill' : 'bi-circle-fill'"></i>
                  </div>
                  <div class="notification-table-cell">
                    <a
                      href="#"
                      :class="{ 'text-muted': notification.read }"
                      @click.prevent="markAsRead(notification.id)"
                    >
                      <i class="bi bi-check-circle-fill" />Mark as Read
                    </a>
                    <a href="#" @click.prevent="assignToUser(notification.id)">
                      <i class="bi bi-person-plus-fill" />Assign
                    </a>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>Loading...</p>
                <LoaderPage />
              </div>
            </div>
          </div>

          <div class="pagination">
            <button
              :disabled="!pagination.previous"
              @click="fetchNotifications(pagination.previous)"
            >
              Previous
            </button>
            <button
              :disabled="!pagination.next"
              @click="fetchNotifications(pagination.next)"
            >
              Next
            </button>
          </div>
          <button @click="markAllAsRead">Mark All as Read</button>
        </div>
      </div>
    </template>
  </MainTemplate>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import axios from "@/utils/axios";
import MainTemplate from "@/components/MainTemplate.vue";
import LoaderPage from "@/components/LoaderPage.vue";

export default {
  name: "NotificationsPage",
  components: { MainTemplate, LoaderPage },
  setup() {
    const searchQuery = ref("");
    const filter = ref("all");
    const sortBy = ref("date");
    const sortOrder = ref("asc");
    const notifications = ref([]);
    const pagination = ref({
      next: null,
      previous: null,
      totalPages: 1,
    });

    const fetchNotifications = async (page = 1) => {
      try {
        const params = {
          page: page,
          search: searchQuery.value,
          sort_by: sortBy.value,
          sort_order: sortOrder.value,
        };

        if (filter.value !== "all") {
          params.filter = filter.value;
        }

        const response = await axios.get("/notifications/notifications/", {
          params: params,
        });

        notifications.value = response.data.results;
        pagination.value.next = response.data.next
          ? new URL(response.data.next).searchParams.get("page")
          : null;
        pagination.value.previous = response.data.previous
          ? new URL(response.data.previous).searchParams.get("page")
          : null;
        pagination.value.totalPages = Math.ceil(response.data.count / 10); // Adjust based on PAGE_SIZE
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    const markAsRead = async (id) => {
      try {
        await axios.patch(`/notifications/notifications/${id}/`, {
          read: true,
        });
        const notification = notifications.value.find((n) => n.id === id);
        if (notification) {
          notification.read = true;
        }
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    };

    const markAllAsRead = async () => {
      try {
        await axios.post(`/notifications/notifications/mark-all-read/`);
        notifications.value.forEach((notification) => {
          notification.read = true;
        });
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
      }
    };

    const assignToUser = async (id) => {
      const userId = prompt("Enter user ID to assign:");
      if (userId) {
        try {
          await axios.post(`/notifications/notifications/${id}/assign/`, {
            userId,
          });
          await fetchNotifications(); // Refresh notifications
        } catch (error) {
          console.error("Error assigning notification:", error);
        }
      }
    };

    const paginatedNotifications = computed(() => {
      return notifications.value;
    });

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
    };

    const sortOrderIcon = computed(() => {
      return sortOrder.value === "asc"
        ? "bi bi-sort-numeric-up"
        : "bi bi-sort-numeric-down";
    });

    onMounted(() => {
      fetchNotifications();
    });

    watch([searchQuery, filter, sortBy, sortOrder], () => {
      fetchNotifications(1);
    });

    return {
      searchQuery,
      filter,
      sortBy,
      sortOrder,
      notifications,
      pagination,
      toggleSortOrder,
      sortOrderIcon,
      markAsRead,
      markAllAsRead,
      assignToUser,
      fetchNotifications,
      paginatedNotifications,
    };
  },
};
</script>




<style scoped>
.notifications-page {
  padding: 1rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  width: 100%;
}

.notifications-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-search {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.filters {
  display: flex;
  gap: 0.5rem;
}

.filters button {
  padding: 0.5rem 1rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.filters button.active {
  background-color: #007bff;
  color: #ffffff;
}

.filters button:hover:not(.active) {
  background-color: #f0f0f0;
}

.sort {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.select-sort {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.button-sort,
.button-mark-all {
  padding: 0.5rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.button-sort:hover,
.button-mark-all:hover {
  background-color: #007bff;
  color: #ffffff;
}

.notification-list {
  margin-top: 1rem;
}

.notification-table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notification-table-header,
.notification-table-body {
  display: flex;
  flex-direction: column;
}

.notification-table-row {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
}

  .notification-table-row.notification-read {
  background-color: #e0f7fa;
}

.notification-table-cell {
  flex: 1;
  padding: 0.5rem;
}

.notification-table-cell div {
  overflow-wrap: break-word;
}

.notification-table-row:hover {
  background-color: #f0f0f0;
}

.notification-table-row .notification-actions {
  display: flex;
  gap: 0.5rem;
}

.notification-actions a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.notification-actions a:hover {
  color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  border-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: #007bff;
  color: #ffffff;
}
</style>

