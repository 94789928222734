<template>
  <div v-if="isLoggedIn" class="sidenav">
    <button class="sidenav-toggle">
      <i class="fa-solid fa-bars" />
    </button>
    <ul class="main-sidenav">
      <li>
        <a @click="updateNavshow('dashboard')">
          <b-icon-speedometer2 /> Dashboard
        </a>
        <div v-show="navshow === 'dashboard'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list /> Overview</a>
            </li>
            <li>
              <a><b-icon-bell /> Alerts & Notifications</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a @click="updateNavshow('crm')"><b-icon-person-fill /> CRM</a>

        <div v-show="navshow === 'crm'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboards </a>
            </li>
            <li @click="$router.push('/notifications')">
              <a><b-icon-card-list />Notifications </a>
            </li>
            <li @click="$router.push('/contacts')">
              <a><b-icon-person-fill />Contact</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Interactions</a>
            </li>
            <li @click="$router.push('/tickets')">
              <a><b-icon-tags-fill />Cases </a>
            </li>

            <li @click="$router.push('/inbox')">
              <a><b-icon-inbox-fill />Mail</a>
            </li>
            <li @click="$router.push('/marketingview')">
              <a><b-icon-megaphone-fill /> Marketing</a>
            </li>
            <li @click="$router.push('/salesview')">
              <a><b-icon-graph-up /> Sales</a>
            </li>

            <li @click="$router.push('/leads')">
              <a><b-icon-bar-chart-fill /> Reporting</a>
            </li>

            <li @click="$router.push('/webconfig')">
              <a><b-icon-globe />Web</a>
            </li>

            <li @click="$router.push('/settings')">
              <a><b-icon-gear-fill />Settings </a>
            </li>
          </ul>
        </div>
      </li>
      <!-- this shuld be the list of Navigations registrations , properies, Jobs, Materials, Financing , crm, subscriptions,  -->

      <li>
        <a @click="updateNavshow('financing')"><b-icon-cash-coin /> Financing</a>
        <div v-show="navshow === 'financing'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboards </a>
            </li>
            <li @click="$router.push('/financing')">
              <a><b-icon-person-fill />Financing Options</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Applications</a>
            </li>
          </ul>
        </div>
      </li>

      <li>
        <a @click="updateNavshow('materials')"> <b-icon-tools /> Materials </a>
        <div v-show="navshow === 'materials'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/materials')">
              <a><b-icon-person-fill /> Material List</a>
            </li>

            <li @click="$router.push('/specifications')">
              <a><b-icon-person-fill /> Contract Materials</a>
            </li>

            <li @click="$router.push('/components')">
              <a><b-icon-person-fill /> Material Components</a>
            </li>


            <ul>
              <li @click="$router.push('/pricelist')">
                <a><b-icon-currency-dollar /> Price Management</a>
              </li>
              <li @click="$router.push('/availability-tracking')">
                <a><b-icon-clock-history />Inventory</a>
              </li>
            </ul>
          </ul>
        </div>
      </li>

      <li>
        <a @click="updateNavshow('suppliers')"> <b-icon-shop /> Suppliers </a>
        <div v-show="navshow === 'suppliers'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/suppliers')">
              <a><b-icon-person-fill /> Supplier List</a>
            </li>
            <li @click="$router.push('/supplier-verification')">
              <a><b-icon-check /> Verification</a>
            </li>
          </ul>
        </div>
      </li>

      <li>
        <a @click="updateNavshow('customers')"> <b-icon-people /> Customers </a>
        <div v-show="navshow === 'customers'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/customer-list')">
              <a><b-icon-person-lines-fill /> Customer List</a>
            </li>
            <li @click="$router.push('/customer-orders')">
              <a><b-icon-cart-check-fill /> Customer Orders</a>
            </li>
            <li @click="$router.push('/customer-feedback')">
              <a><b-icon-chat-dots-fill /> Customer Feedback</a>
            </li>
          </ul>
        </div>
      </li>

      <li>
        <a @click="updateNavshow('property')"><b-icon-house-fill /> Property</a>
        <div v-show="navshow === 'property'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboard </a>
            </li>
            <li @click="$router.push('/property')">
              <a><b-icon-person-fill />Property List</a>
            </li>

            <li @click="$router.push('/property-settings')">
              <a><b-icon-chat-square-dots-fill />Property Features</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a @click="updateNavshow('rentals')"><b-icon-journal-check /> Rental Management</a>

        <div v-show="navshow === 'rentals'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboard </a>
            </li>
            <li @click="$router.push('/rentals')">
              <a><b-icon-person-fill />Rental Property</a>
            </li>
            <li @click="$router.push('/')">
              <a><b-icon-person-fill />Subscribed Properties</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Inquiries</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Applications</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a @click="updateNavshow('subscriptions')"><b-icon-journal-check /> Subscriptions</a>
        <div v-show="navshow === 'subscriptions'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboard </a>
            </li>
            <li @click="$router.push('/subscriptions')">
              <a><b-icon-person-fill />Subscriptions</a>
            </li>

            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Inquiries</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Applications</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a @click="updateNavshow('jobs')"><b-icon-briefcase-fill /> Jobs</a>
        <div v-show="navshow === 'jobs'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Dashboard </a>
            </li>
            <li @click="$router.push('/jobs')">
              <a><b-icon-person-fill />Jobs Listing</a>
            </li>
            <li @click="$router.push('/jobstype')">
              <a><b-icon-person-fill />Jobs Types</a>
            </li>

            <li @click="$router.push('/jobscategory')">
              <a><b-icon-person-fill />Jobs Categories</a>
            </li>
            <li @click="$router.push('/Jobsroles')">
              <a><b-icon-chat-square-dots-fill />Jobs Roles</a>
            </li>
            <li @click="$router.push('/interactions')">
              <a><b-icon-chat-square-dots-fill />Rejections</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a @click="updateNavshow('analytics')"><b-icon-graph-up /> Analytics</a>
      </li>

      <li>
        <a @click="updateNavshow('settings')"><b-icon-briefcase-fill /> Settings</a>
        <div v-show="navshow === 'settings'" class="sub-sidenav">
          <ul>
            <li @click="$router.push('/dashboard')">
              <a><b-icon-card-list />Users </a>
            </li>

            <li @click="$router.push('/materials_catagory')">
              <a><b-icon-layout-text-window-reverse /> Categories & Types</a>
            </li>

            <li @click="$router.push('/county')">
              <a><b-icon-chat-square-dots-fill />County Information</a>
            </li>
            <li @click="$router.push('/locations')">
              <a><b-icon-chat-square-dots-fill />Geo Locations</a>
            </li>

            <li @click="$router.push('/settings')">
              <a><b-icon-chat-square-dots-fill />Settings</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "SideNav",
  setup() {
    const navshow = ref("");
    const authToken = computed(() => localStorage.getItem("appToken"));

    const isLoggedIn = computed(() => authToken.value !== null);

    const updateNavshow = (value) => {
      navshow.value = value;
    };

    return {
      navshow,
      updateNavshow,
      authToken,
      isLoggedIn,
    };
  },
};

</script>

<style></style>
